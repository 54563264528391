export class DiscountCampaignSetting {
    constructor(
        public id: string,
        public discountCampaignId: string,
        public eventCategoryIds: string[]         = [],
        public subscriptionTypeIds: string[]      = [],
        public subscriptionTypePriceIds: string[] = [],
        public eventIds: string[]                 = [],
        public ticketTypeIds: string[]            = [],
        public venueSectionIds: string[]          = [],
        public customerIds: string[]              = [],
        public customerTagIds: string[]           = []) {
    }
}

export function mapDiscountCampaignSetting(item: any): DiscountCampaignSetting {

    return new DiscountCampaignSetting(
        item.id,
        item.discountCampaignId,
        item.eventCategoryIds,
        item.subscriptionTypeIds,
        item.subscriptionTypePriceIds,
        item.eventIds,
        item.ticketTypeIds,
        item.venueSectionIds,
        item.customerIds,
        item.customerTagIds
    );
}
