import {Discount, mapDiscount} from './discount';
import {DiscountCampaignSetting, mapDiscountCampaignSetting} from './discount-campaign-setting';
import {ModelBase} from './abstract/model-base';

export class DiscountCampaign extends ModelBase {
    public id: string = null;
    public name: string = null;
    public mode: string = null;
    public type: string = null;
    public capacity: number = null;
    public amount: number = null;
    public percentage: number = null;
    public minOrderItems: number = null;
    public maxOrderItems: number = null;
    public startAt: string = null;
    public endAt: string = null;
    public discount: Discount = null;
    public discounts: Discount[] = [];
    public discountCampaignSettings: DiscountCampaignSetting[] = [];
    public isDashboard = false;
    public statistics: any = [];
    public currencyId: string = null;

    constructor() {
        super();
        super.setRoutes({
            post: 'v1.0/discountcampaign',
            getMany: 'v1.0/discountcampaign',
            get: 'v1.0/discountcampaign/:id',
            patch: 'v1.0/discountcampaign/:id',
            delete: 'v1.0/discountcampaign/:id'
        });
    }

    public getStatistic(id: string): string | number {
        if (!this.statistics) {
            return 0;
        }

        const statistic = this.statistics.find(statistic => statistic.id === id);

        if (!statistic) {
            return 0;
        }

        return statistic.value;
    }

    mapModel(data: any): DiscountCampaign {
        const model = new DiscountCampaign();
        model.id = data.id;
        model.name = data.name;
        model.mode = data.mode;
        model.type = data.type;
        model.capacity = data.capacity;
        model.amount = data.amount;
        model.percentage = data.percentage;
        model.minOrderItems = data.minOrderItems;
        model.maxOrderItems = data.maxOrderItems;
        model.startAt = data.startAt;
        model.endAt = data.endAt;
        model.discount = data.discount ? mapDiscount(data.discount) : null;
        model.discounts = data.discounts ? data.discounts.map(discount => mapDiscount(discount)) : [];
        model.discountCampaignSettings = data.discountCampaignSettings ? data.discountCampaignSettings.map(discountCampaignSetting => mapDiscountCampaignSetting(discountCampaignSetting)) : [];
        model.isDashboard = data.isDashboard;
        model.statistics = data.statistics;
        model.currencyId = data.currencyId;

        return model;
    }
}

export function mapDiscountCampaign(item: any): DiscountCampaign {
    return new DiscountCampaign().mapModel(item);
}
