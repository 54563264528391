import {ModelBase} from './abstract/model-base';

export class Discount extends ModelBase {
    public id: string = null;
    public discountCampaignId: string = null;
    public code: string = null;
    public amount = 0;
    public usage = 0;
    public totalDiscount = 0;

    constructor() {
        super();
        super.setRoutes({
            getMany: 'v1.0/discountcampaign/:discountCampaignId/discount',
            delete: 'v1.0/discountcampaign/:discountCampaignId/discount/:discountId'
        });
    }

    mapModel(data: any) {
        const model = new Discount();

        model.id = data.id;
        model.discountCampaignId = data.discountCampaignId;
        model.code = data.code;
        model.amount = data.amount;
        model.usage = data.usage ? data.usage : 0;
        model.totalDiscount = data.totalDiscount;

        return model;
    }
}

export function mapDiscount(item: any): Discount {
    return (new Discount()).mapModel(item);
}
